var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loadingStockMovements}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-select',{attrs:{"shadow":false,"options":[
          {
            key: 'null',
            value: null,
            label: 'Semua',
          },
          {
            key: 'true',
            value: true,
            label: 'Diterima',
          },
          {
            key: 'false',
            value: false,
            label: 'Belum Diterima',
          },
        ]},on:{"change":_vm.loadStockMovements},model:{value:(_vm.filter.is_received),callback:function ($$v) {_vm.$set(_vm.filter, "is_received", $$v)},expression:"filter.is_received"}}),_c('base-input',{attrs:{"shadow":false,"type":"search","placeholder":"Cari kode atau nama","debounce":""},on:{"native-input":_vm.loadStockMovements},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('datatable',{attrs:{"columns":[
        {
          id: 'code',
          name: 'Nomor Pengemasan',
        },
        {
          id: 'office_code',
          name: 'Kode Supplier',
        },
        {
          id: 'createdAt',
          name: 'Tanggal Pengemasan',
        },
        {
          id: 'items_count',
          name: 'Total Barang',
          theadClass: 'text-right',
        },
        {
          id: 'status',
          name: 'Status',
          theadClass: 'text-center',
        },
      ],"total":_vm.stockMovements.meta.page.total,"perPage":_vm.stockMovements.meta.page.perPage,"currentPage":_vm.stockMovements.meta.page.currentPage,"meta":_vm.stockMovements.meta,"cursor":""},on:{"pagechanged":_vm.onChangePage},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.stockMovements.data),function(stockMovement){return _c('tr',{key:stockMovement.id,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){$event.preventDefault();_vm.onClickDetail(_vm.getStockMovementIncluded(stockMovement))}}},[_c('td',{class:classes.td},[_c('div',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.getStockMovementIncluded(stockMovement).attributes.code)+" / "+_vm._s(_vm.getStockMovementIncluded(stockMovement).attributes.order_code)+" ")])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getStockMovementIncluded(stockMovement).attributes .origin_office_name)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.getStockMovementIncluded(stockMovement).attributes.updatedAt))+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm.getStockMovementIncluded(stockMovement).attributes .product_summaries ? Object.keys( _vm.getStockMovementIncluded(stockMovement).attributes .product_summaries ).length : '-')+" ")]),_c('td',{class:[classes.td, 'text-center']},[(
                _vm.getStockMovementIncluded(stockMovement).attributes.is_received
              )?_c('base-badge',{attrs:{"color":"green"}},[_vm._v("Diterima")]):_c('base-badge',{attrs:{"color":"red"}},[_vm._v("Belum Diterima")])],1)])})}}])})],1),_c('view-stock-movement-modal',{attrs:{"stock-movement-id":_vm.detailModal.stockMovementId,"visible":_vm.detailModal.visible,"with-receive-input":"","with-batch":"","columns":[],"with-actions":"","with-scan-supplier":""},on:{"close":function($event){_vm.detailModal.visible = false},"received":_vm.onReceived}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }