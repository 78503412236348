<template>
  <base-wrapper :loading="loadingStockMovements">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-select
          :shadow="false"
          :options="[
            {
              key: 'null',
              value: null,
              label: 'Semua',
            },
            {
              key: 'true',
              value: true,
              label: 'Diterima',
            },
            {
              key: 'false',
              value: false,
              label: 'Belum Diterima',
            },
          ]"
          v-model="filter.is_received"
          @change="loadStockMovements"
        />
        <base-input
          :shadow="false"
          type="search"
          placeholder="Cari kode atau nama"
          debounce
          v-model="filter.search"
          @native-input="loadStockMovements"
        />
      </div>
      <datatable
        :columns="[
          {
            id: 'code',
            name: 'Nomor Pengemasan',
          },
          {
            id: 'office_code',
            name: 'Kode Supplier',
          },
          {
            id: 'createdAt',
            name: 'Tanggal Pengemasan',
          },
          {
            id: 'items_count',
            name: 'Total Barang',
            theadClass: 'text-right',
          },
          {
            id: 'status',
            name: 'Status',
            theadClass: 'text-center',
          },
        ]"
        :total="stockMovements.meta.page.total"
        :perPage="stockMovements.meta.page.perPage"
        :currentPage="stockMovements.meta.page.currentPage"
        :meta="stockMovements.meta"
        cursor
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="stockMovement in stockMovements.data"
            :key="stockMovement.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click.prevent="
              onClickDetail(getStockMovementIncluded(stockMovement))
            "
          >
            <td :class="classes.td">
              <div class="font-bold text-gray-900">
                {{ getStockMovementIncluded(stockMovement).attributes.code }} /
                {{
                  getStockMovementIncluded(stockMovement).attributes.order_code
                }}
              </div>
            </td>
            <td :class="classes.td">
              {{
                getStockMovementIncluded(stockMovement).attributes
                  .origin_office_name
              }}
            </td>
            <td :class="classes.td">
              {{
                getStockMovementIncluded(stockMovement).attributes.updatedAt
                  | formatDate
              }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{
                getStockMovementIncluded(stockMovement).attributes
                  .product_summaries
                  ? Object.keys(
                      getStockMovementIncluded(stockMovement).attributes
                        .product_summaries
                    ).length
                  : '-'
              }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                v-if="
                  getStockMovementIncluded(stockMovement).attributes.is_received
                "
                color="green"
                >Diterima</base-badge
              >
              <base-badge v-else color="red">Belum Diterima</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-stock-movement-modal
      :stock-movement-id="detailModal.stockMovementId"
      :visible="detailModal.visible"
      with-receive-input
      with-batch
      :columns="[]"
      with-actions
      with-scan-supplier
      @close="detailModal.visible = false"
      @received="onReceived"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import ViewStockMovementModal from '@/components/stock-movement/view-stock-movement-modal.vue';

export default {
  name: 'Home',
  components: {
    ViewStockMovementModal,
  },
  mixins: [requestMixin],
  data() {
    return {
      detailModal: {
        visible: false,
        stockMovementId: null,
      },
      filter: {
        search: null,
        is_received: false,
      },
      loadingStockMovements: false,
      stockMovements: {
        meta: {
          page: {},
        },
        data: [],
      },
    };
  },
  methods: {
    getStockMovementIncluded(simpleStockMovement) {
      return this.getSingleIncluded(
        this.stockMovements,
        simpleStockMovement.relationships['stock-movement'].data.id
      );
    },
    async loadStockMovements(params) {
      this.loadingStockMovements = true;

      const [res, err] = await this.request('/api/v1/stock-movements', {
        params: {
          'page[limit]': 5,
          'filter[destination_office_category_id]': 1,
          include: 'stock-movement',
          'filter[is_shipped]': true,
          'filter[is_received]': this.filter.is_received,
          'filter[search]': this.filter.search,
          'filter[is_valid_for_shipment]': true,
          ...params,
        },
      });

      if (!err) {
        this.stockMovements = res;
      }

      this.loadingStockMovements = false;
    },
    onChangePage(page) {
      this.loadStockMovements(page);
    },
    onClickDetail(stockMovement) {
      this.detailModal.stockMovementId = stockMovement.id;
      this.detailModal.visible = true;
    },
    onReceived() {
      this.detailModal.visible = false;
      this.loadStockMovements();
    },
  },
  created() {
    this.loadStockMovements();
  },
};
</script>
